<script>
const lgText = 'text-md px-3 py-2'
const smText = 'text-sm px-2.5 py-1.5'
const enabledClass = 'border-primary-200'
const disabledClass = 'cursor-not-allowed border-neutral-100 '
const inputClass = 'ring-0 ring-offset-0 focus:border-blue-200 focus:shadow-input'
const buttonClass = 'bg-transparent cursor-pointer outline-none shadow-none'
const optionClass =
  'flex justify-between w-full gap-2 whitespace-nowrap text-left ' +
  'ring-0 ring-offset-0 focus-visible:bg-gray-200 hover:bg-blue-400 hover:text-white'
const disabledInputClass = 'bg-gray-50 text-gray-700 hover:bg-gray-50 hover:border-gray-500'
const disabledButtonClass =
  'bg-neutral-50 text-neutral-600 ' + 'hover:bg-neutral-50 hover:border-neutral-50 hover:text-neutral-600'

const THEMES = {
  input: { rounding: 'rounded', button: [lgText, inputClass], disabled: disabledInputClass },
  button: { rounding: 'rounded-lg', button: [lgText, buttonClass], disabled: disabledButtonClass },
  small_button: { rounding: 'rounded-lg', button: [smText, buttonClass], disabled: disabledButtonClass },
}
const THEME_KEYS = Object.keys(THEMES)
const HEIGHT_CLASSES = {
  xxs: 'max-h-24',
  xs: 'max-h-36',
  sm: 'max-h-48',
  md: 'max-h-60',
  lg: 'max-h-72',
  xl: 'max-h-84',
  xxl: 'max-h-96',
}
</script>

<script setup>
import { ref, computed, defineModel, nextTick } from 'vue'
import LzIcon from './LzIcon'
import BaseDropdown from './dropdowns/BaseDropdown'

const props = defineProps({
  id: { type: String, required: true },
  options: { type: [Object, Array], required: true },
  placeholder: { type: String, default: '' },
  height: { type: String, default: 'md', validator: (val) => Object.keys(HEIGHT_CLASSES).includes(val) },
  disabled: { type: Boolean, default: false },
  theme: { type: String, default: THEME_KEYS[0], validator: (val) => THEME_KEYS.includes(val) },
})
const model = defineModel()

const theme = computed(() => THEMES[props.theme])
const heightClass = computed(() => HEIGHT_CLASSES[props.height])
const abledClass = computed(() => (props.disabled ? [disabledClass, theme.value.disabled] : [enabledClass]))
const buttonClasses = computed(() => [theme.value.button, theme.value.rounding, ...abledClass.value])
const optionClasses = [smText, buttonClass, optionClass]

const buttonRef = ref(null)
const optionRefs = ref([])

const optionList = computed(() => {
  const list = Array.isArray(props.options) ? props.options : Object.entries(props.options)
  return list.map((opt) => ({ key: opt[0], label: opt[1], lower: opt[1].toLowerCase() }))
})
const selectedIndex = computed(() => optionList.value.findIndex((opt) => opt.key === model.value))
const buttonLabel = computed(() =>
  selectedIndex.value < 0 ? props.placeholder : optionList.value[selectedIndex.value].label,
)
const longestText = computed(() => {
  const labels = optionList.value.map((opt) => opt.label)
  if (props.placeholder) labels.push(props.placeholder)
  return labels.reduce((longest, label) => (label.length > longest.length ? label : longest), '')
})

const setModel = (key) => (model.value = key)
const clampedIndex = (i) => i % optionList.value.length
const optionMatchIndex = (lowKey, after) => {
  if (after < 0) return

  const first = clampedIndex(after + 1)
  const list = optionList.value
  const sequence = first ? [...list.slice(first), ...list.slice(0, first)] : list
  const found = sequence.findIndex((opt) => opt.lower.startsWith(lowKey))
  if (found >= 0) return clampedIndex(first + found)
}
const focusMatch = (lowKey, current) => {
  const after = optionRefs.value.findIndex((opt) => opt === current)
  const found = optionMatchIndex(lowKey, after)
  if (found >= 0) optionRefs.value[found].focus()
}
const selectMatch = (lowKey) => {
  const after = model.value ? selectedIndex.value : optionList.value.length - 1
  const found = optionMatchIndex(lowKey, after)
  if (found >= 0) setModel(optionList.value[found].key)
}

const buttonKeyup = (e) => {
  if ([' ', 'ArrowUp', 'ArrowDown'].includes(e.key)) return false
  if (e.key.length !== 1) return true // Ignore non-printable characters
  selectMatch(e.key.toLowerCase())
  return true
}
const optionKeyup = (e) => {
  if (e.key === 'ArrowUp') return e.target.previousSibling?.focus()
  if (e.key === 'ArrowDown') return e.target.nextSibling?.focus()
  if (e.key.length === 1 && e.key !== ' ') focusMatch(e.key.toLowerCase(), e.target)
}
const optionsToggled = (isOpen) => nextTick(() => (isOpen ? optionRefs.value[0]?.focus() : buttonRef.value?.focus()))
</script>

<template>
  <BaseDropdown :id="id" v-slot="{ isOpen, toggle }" @dropdown-toggled="optionsToggled">
    <div :class="['relative', $attrs.class]">
      <button
        :id="id"
        ref="buttonRef"
        type="button"
        class="flex w-full items-center gap-x-2 border leading-5"
        :class="buttonClasses"
        :aria-label="$attrs['aria-label']"
        :aria-expanded="isOpen"
        :disabled="disabled"
        data-test="select-input-button"
        @keyup.stop.prevent="buttonKeyup($event) || toggle()"
        @click.stop.prevent="toggle"
      >
        <div class="flex flex-col items-start" data-test="select-display">
          {{ buttonLabel }}
          <div class="h-0 overflow-hidden" data-test="width-option" aria-hidden>{{ longestText }}</div>
        </div>
        <LzIcon size="sm" path="icons/caret" aria-hidden />
      </button>
      <div
        v-if="isOpen"
        class="z-dropdown shadow-dropdown absolute left-0 top-auto mt-2 w-full overflow-scroll bg-white"
        :class="[heightClass, theme.rounding]"
        data-test="select-option-list"
      >
        <button
          v-for="{ key, label } in optionList"
          :key="key"
          ref="optionRefs"
          type="button"
          :class="[...optionClasses, { 'font-bold': key === model }]"
          :aria-label="label"
          @keyup.esc="toggle"
          @keyup="optionKeyup"
          @click.stop.prevent="setModel(key) && toggle()"
        >
          <span>{{ label }}</span>
          <LzIcon v-if="key === model" size="sm" path="icons/check" aria-hidden />
        </button>
      </div>
    </div>
  </BaseDropdown>
</template>
