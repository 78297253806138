<!-- eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="switch-toggle">
    <!-- since forms do not submit a falsy checkbox value-->
    <input :name="fieldName" :value="modelValue ? 1 : 0" type="hidden" />
    <!-- This is fine. We don't need aria-checked because we already have checked from the checkbox -->
    <!-- eslint-disable-next-line vuejs-accessibility/role-has-required-aria-props -->
    <input
      :id="id"
      ref="input"
      role="switch"
      :aria-label="ariaLabel"
      :aria-labelledby="ariaLabelledby"
      :checked="modelValue"
      :disabled="disabled"
      :name="fieldName"
      type="checkbox"
      class="switch-toggle__button"
      @change="update"
    />
    <span class="switch-toggle__label" style="color: inherit" :for="id" aria-hidden="true">{{ t('common.off') }}</span>
    <span class="switch-toggle__label" style="color: inherit" :for="id" aria-hidden="true">{{ t('common.on') }}</span>
  </div>
</template>

<script setup>
import { common } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import uniqueId from 'lodash/uniqueId'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  ariaLabel: {
    type: String,
    default: undefined,
  },
  ariaLabelledby: {
    type: String,
    default: () => {},
  },
  id: {
    type: String,
    default: function () {
      return uniqueId('switch-toggle-')
    },
  },
  fieldName: {
    type: String,
    default: null,
  },
})
const { t } = useI18n({ messages: namespaceLocaleObj(common, 'common', { only: ['off', 'on'] }) })

const input = ref(null)
const emit = defineEmits(['update:modelValue'])
const update = () => {
  emit('update:modelValue', input.value.checked)
}
</script>
